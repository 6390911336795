<template>
    <MainContainer>
        <div class="mitarbeiter">
            <h2>Kontakte</h2>
            <b-row class="my-4">
                <b-col md="9">
                    <b-row>
                        <b-col cols="12">
                            <h5>Filter</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <h6>VHS</h6>
                            <CheckboxSelect
                                :options="vhsSelect"
                                v-model="filter.vhs"
                                :size="size"
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button :size="size" @click="applyFilter"
                                >Filter anwenden</b-button
                            >
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="3">
                    <h5>Funktionen</h5>

                    <b-button
                        variant="outline-primary"
                        size="sm"
                        @click="openModificationModal({ mode: 'create' })"
                        >Hinzufügen</b-button
                    >
                    <b-button
                        class="mx-1"
                        variant="outline-danger"
                        size="sm"
                        :disabled="!(selected.length > 0)"
                        @click="openDeleteModal({ multi: true })"
                        >Löschen</b-button
                    >
                    <hr />
                    <h6>Export</h6>
                    <TableExport
                        :busy="busy"
                        :dataAvailable="dataAvailable"
                        @exportExcel="exportExcel"
                        @exportPDF="exportPDF"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col md="9" class="my-4">
                    <TableFilter v-model="filter.pattern" />
                </b-col>
            </b-row>
            <b-table
                :items="mitarbeiter"
                :fields="currentSelectedFields"
                :filter="filter.pattern"
                :busy="busy.data"
                :small="size == 'sm'"
                select-mode="multi"
                sort-by="lastname"
                ref="selectableTable"
                empty-text="Keine Daten vorhanden"
                empty-filtered-text="Für den gewählten Filter wurden keine Daten gefunden"
                foot-clone
                no-footer-sorting
                selectable
                bordered
                striped
                responsive
                show-empty
                @row-selected="onRowSelected"
            >
                <template #thead-top="{ columns }">
                    <b-tr>
                        <b-td>
                            <p class="small m-0">
                                Gesamt: {{ mitarbeiter.length }}
                            </p>
                            <p class="small m-0">
                                Gewählt:
                                <template v-if="selected.length > 0"
                                    >{{ selected.length }}
                                </template>
                                <template v-else>-</template>
                            </p>
                        </b-td>
                        <b-td :colspan="currentRecordFields.length"></b-td>
                        <b-td class="text-right"
                            ><b-dropdown
                                variant="outline-secondary"
                                text="Spaltenauswahl"
                                :size="size"
                            >
                                <b-dropdown-form>
                                    <b-form-checkbox-group
                                        v-model="selectedFields"
                                        :options="recordFields"
                                        value-field="key"
                                        text-field="label"
                                    ></b-form-checkbox-group>
                                </b-dropdown-form>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                </template>

                <template #table-busy>
                    <div class="text-center text-secondary my-2">
                        <b-spinner class="mr-1 align-middle" small></b-spinner>
                        <strong>Daten werden geladen...</strong>
                    </div>
                </template>

                <template #head(__selected)>
                    <input
                        type="checkbox"
                        v-model="selectAll"
                        :true-value="true"
                        :false-value="false"
                        @change="toggleAllSelectedRows"
                    />
                </template>

                <template #cell(__selected)="{ rowSelected }">
                    <input
                        type="checkbox"
                        :checked="rowSelected"
                        :true-value="true"
                        :false-value="false"
                        disabled
                    />
                </template>

                <template #cell(vhs)="data">
                    <b-badge
                        v-for="vhsId in data.value"
                        :key="vhsId"
                        v-show="vhsName(vhsId)"
                        class="mx-1"
                    >
                        {{ vhsName(vhsId) }}
                    </b-badge>
                </template>

                <template #cell(__actions)="data">
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        @click="
                            openModificationModal({
                                record: data.item,
                                mode: 'edit'
                            })
                        "
                        >Bearbeiten</b-button
                    >
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        class="mx-1"
                        @click="openDeleteModal({ record: data.item })"
                        >Löschen</b-button
                    >
                </template>
            </b-table>

            <EditModal
                :busy="busy.mutation"
                :mode="modificationMode"
                @ok="handleModification"
            >
                <b-tabs pills card vertical>
                    <b-tab title="Stammdaten" active>
                        <FormGroupBuilder
                            :fields="ioFields"
                            :data="modificationRecord"
                        />
                    </b-tab>
                    <b-tab title="VHS">
                        <FormGroupBuilder
                            :fields="vhsIoFields"
                            :data="modificationRecord"
                        />
                    </b-tab>
                </b-tabs>
            </EditModal>

            <DeleteModal
                :mode="modificationMode"
                :record="modificationRecord"
                :fields="currentRecordFields"
                :selected="selected"
                :busy="busy.mutation"
                @ok="deleteRecord"
            >
            </DeleteModal>
        </div>
    </MainContainer>
</template>

<script>
import MainContainer from "@/components/MainContainer/MainContainer";
import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";
import EditModal from "@/components/EditModal/EditModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import { mapActions, mapGetters } from "vuex";

import "./Mitarbeiter.scss";

export default {
    name: "Mitarbeiter",
    components: {
        MainContainer,
        FormGroupBuilder,
        TableExport,
        TableFilter,
        EditModal,
        DeleteModal,
        CheckboxSelect
    },
    mixins: [ModelView, Size],
    data() {
        return {
            filter: {
                pattern: null,
                vhs: []
            },
            fields: [
                {
                    key: "__selected",
                    label: "",
                    class: "text-center",
                    static: true,
                    position: "prepend"
                },
                {
                    key: "id",
                    label: "ID",
                    sortable: true
                },
                {
                    key: "firstname",
                    label: "Vorname",
                    sortable: true
                },
                {
                    key: "lastname",
                    label: "Nachname",
                    sortable: true
                },
                {
                    key: "vhs",
                    label: "VHS",
                    sortable: true
                },
                {
                    key: "__actions",
                    label: "Aktionen",
                    class: "text-right",
                    static: true,
                    position: "append"
                }
            ],
            ioFields: {
                id: {
                    key: "id",
                    label: "ID",
                    disabled: true
                },
                firstname: {
                    key: "firstname",
                    label: "Vorname"
                },
                lastname: {
                    key: "lastname",
                    label: "Nachname"
                }
            },
            vhsIoFields: {
                vhs: {
                    key: "vhs",
                    label: "Zugewiesene Volkshochschulen",
                    type: "checkboxselect",
                    options: [],
                    busy: this.vhsBusy
                }
            },
            selectedFields: ["id", "firstname", "lastname", "vhs"],
            actions: {
                create: "mitarbeiter/createMitarbeiter",
                edit: "mitarbeiter/editMitarbeiter",
                delete: "mitarbeiter/deleteMitarbeiter",
                deleteMulti: "mitarbeiter/deleteMultipleMitarbeiter"
            }
        };
    },
    computed: {
        ...mapGetters({
            busy: "mitarbeiter/getBusy",
            mitarbeiter: "mitarbeiter/getMitarbeiter",
            vhsBusy: "vhs/getBusy",
            vhsSelect: "vhs/getVHSForSelect",
            vhsName: "vhs/getVHSName"
        }),
        dataAvailable() {
            return this.mitarbeiter.length > 0;
        }
    },
    created() {
        this.fetch({ filter: this.filter });
        this.fetchVHS();
    },
    watch: {
        vhsSelect: {
            handler(val) {
                this.vhsIoFields.vhs.options = val;
            },
            deep: true
        }
    },
    methods: {
        ...mapActions({
            fetch: "mitarbeiter/fetchMitarbeiter",
            fetchVHS: "vhs/fetchVHS"
        }),
        async exportExcel(format) {
            this.$store.dispatch("mitarbeiter/exportExcel", {
                format,
                filter: this.filter,
                selectedFields: this.selectedFields,
                selectedData: this._discardSelectedData(this.selected, "id")
            });
        },
        async exportPDF(orientation = "P") {
            this.$store.dispatch("mitarbeiter/exportPDF", {
                orientation,
                filter: this.filter,
                selectedFields: this.selectedFields,
                selectedData: this._discardSelectedData(this.selected, "id")
            });
        },
        applyFilter() {
            this.fetch({ filter: this.filter });
        }
    }
};
</script>
